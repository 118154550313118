import { useMutation, useQuery } from "react-query";
import { request } from "./request";
import useWebsiteStore from "../store/store";

const deleteUser = async (payload, token) => {
  try {
    const response = await request.delete("/api/c_panel/delete_user", {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
const useDeleteUser = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["delete-user", payload],
    () => deleteUser(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};

const getAllUser = async (payload, token) => {
  const response = await request.post("/api/c_panel/get_all_user", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useGetAllUser = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-all-user", payload], () => getAllUser(payload, token));
};

const addUser = async (data, token) => {
  const response = await request.post("/api/c_panel/add_user", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useAddUser = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(["add-user", payload], () => addUser(payload, token), {
    onSuccess,
    onError,
  });
};

const editUser = async (payload, token) => {
  const response = await request.put("/api/c_panel/update_user", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useUpdateUser = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(["edit-user", payload], () => editUser(payload, token), {
    onSuccess,
    onError,
  });
};

const getUserById = async (payload, token) => {
  const response = await request.post(
    "/api/c_panel/get_user",
    {},
    {
      params: payload,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetUserById = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-user", payload], () => getUserById(payload, token));
};

const getAllUsersFriends = async (userId, payload, token) => {
  const response = await request.post(
    `/api/c_panel/get_friends_for_user?userId=${userId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetAllFriendsForUser = (userId, payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-all-users-friends", payload], () =>
    getAllUsersFriends(userId, payload, token)
  );
};

const getPointsRegister = async (userId, payload, token) => {
  const response = await request.post(
    `/api/c_panel/get_pionts_register?userId=${userId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetPointsRegister = (userId, payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-points-register", userId, payload], () =>
    getPointsRegister(userId, payload, token)
  );
};

const getAllUsersConversation = async (userId, payload, token) => {
  const response = await request.post(
    `/api/c_panel/gey_conversions_for_user?userId=${userId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetAllUsersConversation = (userId, payload) => {
  const token = useWebsiteStore((state) => state.token);
  // console.log(token);

  return useQuery(["get-all-users-conversation", payload], () =>
    getAllUsersConversation(userId, payload, token)
  );
};

const getConvDetail = async (userId, payload, token) => {
  const response = await request.post(
    `/api/c_panel/get_conversation_detail?convId=${userId}`,
    payload,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};

const useGetConvDetails = (userId, payload) => {
  const token = useWebsiteStore((state) => state.token);

  return useQuery(["get-conv-detail", payload, userId], () =>
    getConvDetail(userId, payload, token)
  );
};
export {
  useGetAllUser,
  useAddUser,
  useUpdateUser,
  useDeleteUser,
  useGetUserById,
  useGetAllFriendsForUser,
  useGetPointsRegister,
  useGetAllUsersConversation,
  useGetConvDetails,
};
