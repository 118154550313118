import React, { useState } from "react";
import Statistic from "../components/home/statistic";
import PieStatistic from "../components/home/pie-statistic";
import Heading from "../components/heading";
import Card from "../components/home/card";
import cards from "../components/home/cards";
import { useGetMain } from "../hook/main";
import { CircularProgress } from "@mui/material";

const Home = () => {
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [type, setType] = useState("level");
    const payload = {
        startDate: from ? new Date(from)?.toISOString() : "",
        endDate: to ? new Date(to)?.toISOString() : "",
        filter: type,
    };

    const { data, isLoading } = useGetMain(payload);

    return (
        <div>
            {" "}
            <div>
                <Heading title={"الرئيسية"} subtitle={"الرئيسية"} />
            </div>
            <div className=" flex sm:flex-row flex-col gap-5  sm:gap-[4rem] justify-start max-sm:justify-center  md:w-[80%] w-full py-[2rem]">
                {isLoading ? (
                    <div className="w-full flex justify-center items-center h-[21vh]">
                        {" "}
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Card
                            title={cards[0].title}
                            number={data?.data?.numberOfPosts}
                            icon={cards[0].icon}
                            line={cards[0].line}
                            fill={cards[0].fill}
                            color={cards[0].color}
                        />
                        <Card
                            title={cards[1].title}
                            number={data?.data?.numberOfUsers}
                            icon={cards[1].icon}
                            line={cards[1].line}
                            fill={cards[1].fill}
                            color={cards[1].color}
                        />
                        <Card
                            title={cards[2].title}
                            number={data?.data?.numberOfServices}
                            icon={cards[2].icon}
                            line={cards[2].line}
                            fill={cards[2].fill}
                            color={cards[2].color}
                        />
                    </>
                )}
            </div>
            <PieStatistic
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                data={data}
                type={type}
                setType={setType}
            />
            <Statistic
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                data={data}
            />
        </div>
    );
};

export default Home;
