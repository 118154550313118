import React, { useState, useEffect, useRef } from "react";
import useWebsiteStore from "../store/store";
import { useLocation } from "react-router";
import Message from "../components/chat/message";
import Replay from "../components/chat/replay";
import { GrAttachment } from "react-icons/gr";
import { FaChevronRight } from "react-icons/fa";
import { useGetAllConversation } from "../hook/notification";
import { CircularProgress } from "@mui/material";

export const NotificationChat = () => {
    const [conect, setConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const socketRef = useRef(null); // Use a ref to store the WebSocket instance
    const token = useWebsiteStore((state) => state.token);
    const location = useLocation();
    const converstionId = location.pathname.split("/")[2];
    const [loading, setLoading] = useState(false);
    const { data } = useGetAllConversation(converstionId);

    useEffect(() => {
        if (data) {
            setMessages(data.data.messages.slice().reverse());
        }
    }, [data]);

    useEffect(() => {
        const socket = new WebSocket(
            `wss://www.oscarrmix.com/chat/connect?token=${token}`
        );
        socketRef.current = socket;

        socket.onopen = () => {
            setConnected(true);
            console.log("WebSocket connected");
        };

        socket.onerror = (event) => {
            console.error("WebSocket error:", event);
        };
        socket.onclose = () => {
            setConnected(false);
            console.log("WebSocket closed");
        };

        socket.addEventListener("message", (event) => {
            try {
                const newMessageObject = JSON.parse(event.data);
                const parsedData = JSON.parse(newMessageObject.data);
                newMessageObject.data = parsedData.data;

                setMessages((prevMessages) => [
                    ...prevMessages,
                    newMessageObject,
                ]);
            } catch (error) {
                console.error("Error parsing incoming message:", error);
            }
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [token]);

    const handleSendMessage = () => {
        setLoading(true);
        if (newMessage.trim()) {
            const socket = socketRef.current;
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(
                    JSON.stringify({
                        data: newMessage,
                        type: "Message",
                        converstionId,
                    })
                );
                setNewMessage("");
                setLoading(false);
            } else {
                console.warn("WebSocket is not open. Message not sent.");
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const element = document.getElementById("chatNo");
        if (element) {
            element.scrollTop = element.scrollHeight - element.clientHeight;
        }
    }, [messages]);

    useEffect(() => {
        console.log(messages);
    }, [messages]);
    return (
        <div className="flex h-full overflow-auto">
            <div />
            <div className="flex flex-col  w-full border-e overflow-auto border-slate-200 pb-2 ">
                <div className="flex items-center w-full sm:justify-end justify-between px-6 py-4 border-b border-slate-200 ">
                    <p className="cursor-pointer text-base font-bold   sm:hidden  rounded-full text-main">
                        <FaChevronRight />
                    </p>
                    <div className=" flex justify-end  gap-2 items-center ">
                        <div className="flex flex-col  justify-end">
                            <p className="text-sm ">{data?.data.name}</p>
                            <div className="flex items-center justify-end gap-1">
                                <p className="text-[10px] text-[#707991]">
                                    online
                                </p>
                                <span className="w-2 h-2 bg-[#68D391] rounded-full" />
                            </div>
                        </div>
                        <img src="/assets/images/image.png" alt="" />
                    </div>
                </div>
                <div
                    className="h-full overflow-auto relative pt-4  px-6"
                    id="chatNo"
                >
                    {loading ? (
                        <div className="w-full flex justify-center items-center h-[50vh]">
                            {" "}
                            <CircularProgress />
                        </div>
                    ) : (
                        <div className="relative  w-full flex flex-col gap-2 ">
                            {messages.map((e) =>
                                !e.isRecived ? (
                                    <Message key={e.id} e={e} />
                                ) : (
                                    <Replay e={e} key={e.id} />
                                )
                            )}
                        </div>
                    )}
                </div>
                <div className="w-full relative py-4 px-4 flex items-center gap-4">
                    <button onClick={handleSendMessage} disabled={!conect}>
                        <img
                            src="/assets/images/sent.png"
                            className="absolute   right-7 top-1/2 -translate-y-1/2 mr-[20px]"
                            alt=""
                        />
                    </button>
                    <input
                        type="text"
                        name=""
                        className="border outline-none text-black/70 focus:placeholder:opacity-0 placeholder:transition-all w-full ltr border-stone-200 px-4 pr-10 rounded-md py-2"
                        placeholder="اكتب رسالة"
                        id=""
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <GrAttachment className="text-xl cursor-pointer" />
                </div>
            </div>
        </div>
    );
};
