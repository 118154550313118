import { Button, Container, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    useDeleteServices,
    useGetServiceById,
    updateService,
} from "../../hook/services";
import useWebsiteStore from "../../store/store";
import BaseInput from "../base-input";
import BaseTextarea from "../base-textarea";
import InputImage from "../input-image";
import { toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";

const EditService = ({ setOpen, serviceId, refetch, open }) => {
    const token = useWebsiteStore((state) => state.token);
    const [mounted, setMounted] = useState(true);
    const { data: info, isLoading } = useGetServiceById({ id: serviceId });
    const [ImageUrl, setImageUrl] = useState(info?.data.imageUrl);

    const [data, setData] = useState({
        title: "",
        description: "",
    });
    useEffect(() => {
        setData({ ...data, profileImageUrl: ImageUrl });
    }, [ImageUrl]);
    const update = async () => {
        setLoading(true);
        try {
            const payload = {
                imageUrl: ImageUrl,
                title: data.title,
                description: data.description,
            };
            await updateService(serviceId, payload, token);
            refetch();
            setLoading(false);
            toast.success("Service updated successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        } catch (error) {
            setLoading(false);
            toast.error("Error updating Service");
            console.error("Error updating service:", error);
        }
    };
    useEffect(() => {
        if (!isLoading && info && mounted) {
            setData({
                description: info?.data?.description,
                title: info?.data?.title,
            });
            setImageUrl(info?.data?.imageUrl);
            setMounted(false);
        }
    }, [info, isLoading, mounted]);

    const [loading, setLoading] = useState(false);
    const { mutate } = useDeleteServices({
        onSuccess: () => {
            refetch();
            setLoading(false);
            toast.success("Service deleted successfully!");
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        },
        onError: (error) => {
            setLoading(false);
            toast.error("Error deleting Service");
        },
        id: serviceId,
    });

    const handleDeleteService = () => {
        setLoading(true);
        mutate();
    };

    return (
        <AnimatePresence>
            {open && (
                <motion.div className="fixed inset-0 flex items-center justify-center z-20 bg-[#00000033] bg-opacity-50">
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="bg-white shadow-lg z-40 relative rounded-[5px]  sm:w-[711px] w-[95%]"
                    >
                        <div className="flex flex-row justify-between items-center bg-[#D3EDB4] rounded-[5px] py-[15px] pr-[35px] pl-[18px] ">
                            <p className="font-light text-[16px] text-black">
                                تعديل الخدمة
                            </p>
                            <img
                                src="/assets/images/close.svg"
                                alt="close"
                                onClick={() => {
                                    setOpen(false);
                                }}
                                className="cursor-pointer"
                            />
                        </div>
                        {isLoading ? (
                            <div className="w-full flex justify-center items-center h-[50vh]">
                                {" "}
                                <CircularProgress />
                            </div>
                        ) : (
                            <Container>
                                <div className=" flex sm:flex-row flex-col gap-2    justify-between p-2 py-[3rem]  ">
                                    <div>
                                        <div className="md:w-[292px] w-full">
                                            <BaseInput
                                                label={"عنوان الخدمه"}
                                                value={data.title}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        title: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div className=" md:w-[292px] w-full">
                                            <BaseTextarea
                                                label={"وصف الخدمه"}
                                                value={data.description}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        description:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className=" w-[329px]  h-[247px]   flex flex-col gap-2 ">
                                        {info && (
                                            <InputImage
                                                type="ServiceImage"
                                                ImageUrl={ImageUrl}
                                                setImageUrl={setImageUrl}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className=" flex flex-row  justify-end gap-[10px] p-[3rem] ">
                                    {" "}
                                    <Button
                                        variant="contained"
                                        onClick={update}
                                        disabled={loading}
                                    >
                                        تعديل
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setOpen(false);
                                        }}
                                        variant="outlined"
                                    >
                                        تراجع
                                    </Button>
                                    <Button
                                        onClick={handleDeleteService}
                                        color="error"
                                        variant="outlined"
                                        disabled={loading}
                                    >
                                        حذف
                                    </Button>
                                </div>
                            </Container>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default EditService;
