import { useMutation, useQuery } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";

const getAllSettings = async (payload, token) => {
  const response = await request.post("/api/c_panel/settings", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useGetSettings = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-settings", payload], () =>
    getAllSettings(payload, token)
  );
};
const getAllCategory = async (payload, token) => {
  const response = await request.post("/api/c_panel/get_all", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useGetCategory = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-category", payload], () =>
    getAllCategory(payload, token)
  );
};

export const updateAboutInSettings = async (about, token) => {
  try {
    const response = await request.post(
      `/api/c_panel/edit_about`,
      {},
      {
        params: { about },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateAllowedPostsInSettings = async (numberPost, token) => {
  try {
    const response = await request.post(
      `/api/c_panel/edit_posts_allowed`,
      null,
      {
        params: { numberPost },
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const addCategory = async (data, token) => {
  const response = await request.post("/api/c_panel/category_add", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useAddCategory = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["add-category", payload],
    () => addCategory(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};
const deleteCategory = async (payload, token) => {
  try {
    const response = await request.delete("/api/c_panel/category_delete", {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const useDeleteCategory = ({ onSuccess, onError, payload, token }) => {
  const tokenFromStore = useWebsiteStore((state) => state.token);
  return useMutation(
    ["delete-category", payload],
    () => deleteCategory(payload, tokenFromStore),
    {
      onSuccess,
      onError,
    }
  );
};

const getCategoryById = async (payload, token) => {
  const response = await request.get("/api/c_panel/category_get_by_id", {
    params: payload,
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};
const useGetCategoryById = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-category", payload], () =>
    getCategoryById(payload, token)
  );
};

export const updateCategory = async (data, token, id) => {
  try {
    const response = await request.put(`/api/c_panel/category_update`, data, {
      params: { id },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  useGetSettings,
  useAddCategory,
  useDeleteCategory,
  useGetCategoryById,
  useGetCategory,
};
