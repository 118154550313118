import { useQuery } from "react-query";
import useWebsiteStore from "../store/store";
import { request } from "./request";

const getMain = async (payload, token) => {
    const response = await request.post("/api/c_panel/get_main", payload, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
};

const useGetMain = (payload) => {
    const token = useWebsiteStore((state) => state.token);

    return useQuery(["get-main", payload], () => getMain(payload, token));
};
export { useGetMain };
