import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import useLogin from "../hook/login";
import { toast } from "react-toastify";
import useWebsiteStore from "../store/store";
import { key, saveToSessionStorage } from "../utils/helpers";
const Login = () => {
    const [data, setData] = useState({
        nameId: "",
        password: "",
        fcmToken: "",
    });
    const [errors, setErrors] = useState({
        nameId: "",
        password: "",
    });

    const router = useNavigate();

    const setToken = useWebsiteStore((state) => state.setToken);

    const addEmployeMutation = useLogin({
        onSuccess: (request) => {
            setToken(request.data.token);
            saveToSessionStorage(key, request.data.token);
            toast.success("welcome!");
            router("/", { replace: true });
        },
        onError: (error) => {
            toast.error(error.message);
        },
        payload: data,
    });

    const handleSubmit = async () => {
        if (!validateForm()) return;
        try {
            await addEmployeMutation.mutateAsync();
        } catch (error) {
            console.error("Error adding employe:", error);
        }
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {};

        if (!data.nameId.includes("@gmail.com")) {
            errors.nameId = "الرجاء ادخال الايميل بالشكل الصحيح";
            isValid = false;
        }

        if (data.password.length < 6) {
            errors.password = "يجب الا تقل كلمة السر عن 6 رموز";
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    return (
        <div className=" relative w-full h-[100vh] flex justify-center items-center ">
            <img
                src="/assets/images/bg-layout.svg"
                alt="bg-layout"
                className=" w-[100%] h-[100%] absolute left-0 top-0  object-cover  z-0 "
            />
            <Container>
                <div className="    relative z-10  flex flex-col  gap-16  ">
                    <h1 className="text-[40px]  font-normal ">تسجيل الدخول </h1>
                    {/* login box */}
                    <div className=" flex flex-col gap-[24px]  h-auto xsm:w-[450px] w-[95%] p-[32px]  xsm:min-h-[534px] bg-white rounded-[6px] shadow-custom  justify-center items-center   m-auto">
                        <h1 className=" text-[26px]  font-bold  w-fit mx-auto font-baloo">
                            {" "}
                            Logo{" "}
                        </h1>
                        <div className=" flex flex-col gap-[5px] w-full">
                            <p
                                dir="ltr"
                                className=" w-full text-[22px]   font-public-sans font-semibold text-paragraph"
                            >
                                Welcome to dashboard! 👋
                            </p>
                            <p className=" w-full text-[15px]  font-normal font-public-sans text-paragraph">
                                الرجاء تسجيل الدخول إلى حسابك{" "}
                            </p>
                        </div>
                        <div className=" flex flex-col gap-[5px] w-full">
                            <div className=" w-full  flex flex-col gap-[4px] ">
                                <p className=" font-normal text-[13px] font-public-sans w-full  text-paragraph">
                                    الايميل
                                </p>
                                <input
                                    dir="ltr"
                                    type="text"
                                    value={data.nameId}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            nameId: e.target.value,
                                        })
                                    }
                                    placeholder="john.doe@gmail.com"
                                    className="   focus:placeholder:opacity-0 focus:opacity-90  transition-all placeholder:transition-all
                                      outline-none  py-[7px] px-[14px] border   border-1  rounded-[6px]  border-light  w-full "
                                />
                                {errors.nameId && (
                                    <p className="text-error">
                                        {errors.nameId}
                                    </p>
                                )}
                            </div>
                            <div className=" w-full  flex flex-col gap-[4px] ">
                                <p className=" font-normal text-[13px] font-public-sans w-full  text-paragraph">
                                    كلمة السر
                                </p>
                                <input
                                    dir="ltr"
                                    type="password"
                                    value={data.password}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            password: e.target.value,
                                        })
                                    }
                                    placeholder="................."
                                    className="
                                       focus:placeholder:opacity-0 focus:opacity-90 transition-all placeholder:transition-all    
                                      outline-none  py-[7px] px-[14px] border   border-1  rounded-[6px]  border-light  w-full "
                                />{" "}
                                {errors.password && (
                                    <p className="text-error">
                                        {errors.password}
                                    </p>
                                )}
                                <p
                                    onClick={() =>
                                        router("/login/forget-password")
                                    }
                                    dir="ltr"
                                    className="  font-normal text-[13px]  text-error mt-[10px] cursor-pointer"
                                >
                                    {" "}
                                    نسيت كلمة السر؟
                                </p>
                            </div>
                        </div>
                        <div className=" w-full flex flex-col gap-[12px] ">
                            <div className=" w-full">
                                <FormControlLabel
                                    dir="rtl"
                                    control={<Checkbox defaultChecked />}
                                    label="تذكرني"
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                            fontSize: "13px",
                                            fontWeight: "normal",
                                            color: "#4B465C",
                                            width: "auto",
                                        },
                                    }}
                                />
                            </div>
                            <Button
                                variant="contained"
                                onClick={() => handleSubmit()}
                                disabled={addEmployeMutation.isLoading}
                            >
                                {addEmployeMutation.isLoading ? (
                                    <CircularProgress />
                                ) : (
                                    " تسجيل الدخول"
                                )}
                            </Button>
                        </div>
                    </div>{" "}
                </div>
            </Container>
        </div>
    );
};

export default Login;
