import React, { useState } from "react";
import { TbPhotoFilled } from "react-icons/tb";
import { useAddFile } from "../../hook/image";
import { baseURL } from "../../hook/request";
import { CircularProgress } from "@mui/material";

const Image = ({ setImageUrl, ImageUrl, type }) => {
    const [loading, setLoading] = useState(false);
    const { mutate } = useAddFile({
        onSuccess: (result) => {
            setLoading(false);
            setImageUrl(result);
        },
        onError: (error) => {
            setLoading(false);
            console.log(error.message);
        },
        type,
    });

    const changeHandler = (e) => {
        setImageUrl("");
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            mutate(file);
        }
    };

    return (
        <div className="  w-full h-full  flex flex-col gap-2 ">
            <form
                onClick={() => document.querySelector(".add-service").click()}
                action=""
                className=" overflow-hiddenp object-cover  flex flex-col justify-center items-center  cursor-pointer "
            >
                <input
                    type="file"
                    accept="image/*"
                    hidden
                    className="add-service"
                    onChange={changeHandler}
                />

                {ImageUrl && ImageUrl.length !== 0 ? (
                    <img
                        src={`${baseURL}${ImageUrl}`}
                        alt=""
                        width={40}
                        height={22}
                        className=" object-cover"
                    />
                ) : (
                    !loading && (
                        <TbPhotoFilled
                            size={22}
                            className="text-xl cursor-pointer"
                            fill="#0189C7"
                        />
                    )
                )}
                {loading && <CircularProgress size={20} />}
            </form>
        </div>
    );
};

export default Image;
