import { useMutation, useQuery } from "react-query";
import { request } from "./request";
import useWebsiteStore from "../store/store";

const deleteEmploye = async (payload, token) => {
  try {
    const response = await request.delete("/api/c_panel/delete_user", {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting employee:", error);
    throw error;
  }
};
const useDeleteEmployee = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["delete-employe", payload],
    () => deleteEmploye(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};
const BlockEmploye = async (payload, token) => {
  try {
    const response = await request.put(`/api/c_panel/block_user`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error Blocking employee:", error);
    throw error;
  }
};

const useBlockEmployee = ({ onSuccess, onError, userIds }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["block-user", userIds],
    () => BlockEmploye({ userIds }, token),
    {
      onSuccess,
      onError,
    }
  );
};

const getRole = async (token) => {
  try {
    const response = await request.get("/api/c_panel/get_roles", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching roles:", error);
    throw error;
  }
};

const useGetRole = () => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery("get-roles", () => getRole(token), {
    onError: (error) => {
      console.error("Error fetching roles:", error);
    },
  });
};

const getAllEmploye = async (payload, token) => {
  const response = await request.post("/api/c_panel/get_all_employe", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useGetAllEmploye = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-all-employ", payload], () =>
    getAllEmploye(payload, token)
  );
};

const getEmployeeById = async (payload, token) => {
  const response = await request.post(
    "/api/c_panel/get_employ",
    {},
    {
      params: payload,
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
};
const useGetEmployeeById = (payload) => {
  const token = useWebsiteStore((state) => state.token);
  return useQuery(["get-employ", payload], () =>
    getEmployeeById(payload, token)
  );
};

const addEmploye = async (data, token) => {
  const response = await request.post("/api/c_panel/add_employe", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useAddEmploye = ({ onSuccess, onError, payload }) => {
  console.log(payload);
  const token = useWebsiteStore((state) => state.token);
  return useMutation(
    ["add-employe", payload],
    () => addEmploye(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};

export const updateEmp = async (id, data, token) => {
  try {
    const response = await request.put(`/api/c_panel/update_employe`, data, {
      params: { id },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const editEmploye = async (payload, token) => {
  const response = await request.post("/api/c_panel/add_employe", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

const useEditEmploye = ({ onSuccess, onError, payload }) => {
  const token = useWebsiteStore((state) => state.token);

  return useMutation(
    ["edit-employe", payload],
    () => editEmploye(payload, token),
    {
      onSuccess,
      onError,
    }
  );
};

export {
  useGetAllEmploye,
  useAddEmploye,
  useEditEmploye,
  useGetEmployeeById,
  useGetRole,
  useDeleteEmployee,
  useBlockEmployee,
};
