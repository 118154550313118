import { useState } from "react";
import Heading from "../components/heading";
import InputSearch from "../components/inputSearch";
 
import CardNotification from "../components/notifications/card-notification";

import { NavLink } from "react-router-dom";
import {
    EnumTypeNotifications,
    useGetAllNotifications,
} from "../hook/notification";
import PaginationCom from "../components/pagination";
import { useDebounce } from "use-debounce";
import AddNotification from "../components/notifications/add-notification";
import { Button } from "@mui/material";

// notification svg
const svg = `<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 6C13.5 4.80653 13.0259 3.66193 12.182 2.81802C11.3381 1.97411 10.1935 1.5 9 1.5C7.80653 1.5 6.66193 1.97411 5.81802 2.81802C4.97411 3.66193 4.5 4.80653 4.5 6C4.5 11.25 2.25 12.75 2.25 12.75H15.75C15.75 12.75 13.5 11.25 13.5 6Z" stroke="#0189C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.2976 15.75C10.1658 15.9773 9.97652 16.166 9.74881 16.2971C9.52109 16.4283 9.26292 16.4973 9.00014 16.4973C8.73735 16.4973 8.47918 16.4283 8.25147 16.2971C8.02375 16.166 7.83449 15.9773 7.70264 15.75" stroke="#0189C7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const Notifications = () => {
    const [open, setOpen] = useState(false);
    const [ids, setIdsArray] = useState([]);
    const [PageNumber, setPageNumber] = useState(1);
    const [search, setSearch] = useState("");
    const [key] = useDebounce(search, 500);
    const handleCheck = (e) => {
        console.log(e);

        if (ids.includes(e)) {
            setIdsArray(ids.filter((user) => user !== e));
        } else {
            setIdsArray((prevIds) => [...prevIds, e]);
        }
    };

    const checkAll = () => {
        if (ids.length === data?.data?.length) setIdsArray([]);
        else {
            setIdsArray([]);
            setIdsArray(data?.data?.map((item) => item.id));
        }
    };
    const { data, refetch } = useGetAllNotifications({
        type: EnumTypeNotifications.Notification,
        IsPaginat: true,
        PageNumber: PageNumber,
        PageSize: 10,
        key,
    });
    return (
        <>
            <AddNotification refetch={refetch} open={open} setOpen={setOpen} />
            <div className=" py-[10px] md:px-[30px] px-[10px]  bg-[#f8f8f8f8]">
                <div className=" flex sm:flex-row flex-col justify-between xsm:items-center w-full gap-[15px] ">
                    <Heading
                        title={"الاشعارات"}
                        subtitle={"الاشعارات"}
                        icon={svg}
                    />
                    <div className="tabsNoti flex gap-2 text-black/80 text-sm">
                        <NavLink to="/notifications">المرسلة</NavLink>
                        <NavLink to="/imported">الورادة</NavLink>
                    </div>
                    <div className=" flex ic flex-row justify-between items-center  gap-[10px] flex-wrap  w-full sm:w-auto">
                        <InputSearch
                            placeholder="ابحث عن اشعار محدد"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <span onClick={() => setOpen(true)}>
                            <Button variant="contained">اشعار جديد +</Button>
                        </span>
                    </div>
                </div>
                <div className="mt-4 ">
                    <div className="w-full flex justify-between items-center bg-white px-4 py-4  border border-[#ededed]">
                        <div
                            className="flex items-center gap-3 cursor-pointer "
                            onClick={checkAll}
                        >
                            <div />{" "}
                            {ids.length === data?.data?.length &&
                            ids.length !== 0 ? (
                                <img
                                    src="/assets/svg/checkbox.svg"
                                    className=" w-4 h-4  mx-auto  cursor-pointer "
                                    alt="checked"
                                />
                            ) : (
                                <p className="w-4 h-4 border border-input mx-auto rounded-sm cursor-pointer"></p>
                            )}{" "}
                            <span className="tex-xs">تحديد الكل</span>
                        </div>
                    </div>
                    <div>
                        {data?.data?.map((e) => (
                            <CardNotification
                                title={e.title}
                                date={e.date}
                                key={e.id}
                                body={e.body}
                                typeSend={e.typeSend}
                                id={e.id}
                                ids={ids}
                                handleCheck={handleCheck}
                            />
                        ))}
                    </div>
                </div>
                <div className="ltr mx-auto w-fit mt-4">
                    <PaginationCom
                        count={data?.totalPage}
                        page={PageNumber}
                        setPage={setPageNumber}
                        size="small"
                        color="primary"
                    />
                </div>
            </div>
        </>
    );
};

export default Notifications;
