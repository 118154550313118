import React from "react";

const LightButton = ({ title }) => {
    return (
        <button className="  rounded-[10px] border border-1 whitespace-nowrap text-main border-main w-fit  text-[14px]  font-medium    py-[10px] px-[20px]">
            {title}
        </button>
    );
};

export default LightButton;
