import React, { PureComponent } from "react";
import { PieChart, Pie, Cell } from "recharts";
import PieHeader from "./pie-header";
import { baseURL } from "../../hook/request";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={"middle"}>
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

export default class PieStatistic extends PureComponent {
    static demoUrl =
        "https://codesandbox.io/s/pie-chart-with-padding-angle-7ux0o";
    render() {
        const filterUsers = this.props.data?.data?.filterUser;
        const data = filterUsers?.map((user) => {
            return {
                name: user.group,
                value: user.value,
            };
        });
        const image = this.props.data?.data?.filterUser?.[0]?.profileImageUrl;

        return (
            <div className=" md:w-[80%] w-full  p-[10px] rounded-[6px]  bg-white my-[30px] ">
                <PieHeader
                    from={this.props.from}
                    setFrom={this.props.setFrom}
                    to={this.props.to}
                    setTo={this.props.setTo}
                    type={this.props.type}
                    setType={this.props.setType}
                />
                <div className=" flex sm:flex-row flex-col  justify-evenly  ">
                    <div className="relative object-contain md:min-h-[280px] min-h-[100px]">
                        {image ? (
                            <img
                                src={`${baseURL}${image}`}
                                alt=""
                                className="w-1/2 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute h-1/2 rounded-full"
                            />
                        ) : (
                            <>
                                <br />
                                <br />
                            </>
                        )}
                        {data && data.length > 0 ? (
                            <PieChart
                                width={290}
                                height={290}
                                style={{
                                    maxWidth: "100%",
                                }}
                                className="relative"
                                onMouseEnter={this.onPieEnter}
                            >
                                <Pie
                                    cx="50%"
                                    cy="50%"
                                    data={data}
                                    label={renderCustomizedLabel}
                                    innerRadius={80}
                                    outerRadius={120}
                                    fill="#8884d8"
                                    paddingAngle={0}
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell
                                            className="w-fit"
                                            key={`cell-${index}`}
                                            fill={COLORS[index % COLORS.length]}
                                        />
                                    ))}
                                </Pie>
                            </PieChart>
                        ) : (
                            <div className="text-paragraph-light text-[22px] font-light text-center  ">
                                لا يوجد بيانات
                            </div>
                        )}
                    </div>
                    <div className=" felx flex-col   ">
                        <div className="flex flex-row gap-[40px] justify-between mb-[20px]">
                            <h2 className=" text-[13px] font-bold text-paragraph sm:text-end text-center">
                                اسم المستخدم
                            </h2>
                            <h2 className=" text-[16px] font-bold text-paragraph sm:text-end text-center">
                                {this.props.type}
                            </h2>
                        </div>

                        {filterUsers?.length > 0 ? (
                            filterUsers?.slice(0, 4).map((user, index) => (
                                <div
                                    key={index}
                                    className="flex gap-[20px] flex-row justify-between my-[10px] "
                                >
                                    <div>
                                        {" "}
                                        <div className=" flex flex-row gap-4 items-center  justify-center">
                                            <div
                                                dir=" ltr"
                                                className=" w-[100px]  h-[14px] rounded-2xl bg-[#E0E0E0]"
                                            >
                                                <div
                                                    dir=" ltr"
                                                    className=" h-full rounded-2xl"
                                                    style={{
                                                        width: `${
                                                            (user?.value /
                                                                100) *
                                                            100
                                                        }%`,
                                                        backgroundColor:
                                                            COLORS[
                                                                index %
                                                                    COLORS.length
                                                            ],
                                                    }}
                                                >
                                                    {" "}
                                                </div>
                                            </div>
                                            <p className=" text-[12px] text-paragraph-light  font-light ">
                                                {user.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        {" "}
                                        <div className="  border border-dashed border-paragraph-light rounded-2xl flex  justify-center items-center  min-w-[76px] ">
                                            {" "}
                                            <p>{user.value}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-paragraph-light text-[22px] font-light text-center min-h-[100px]">
                                لا يوجد مستخدمين
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
