import React from "react";

const Heading = ({ title, icon, subtitle }) => {
    return (
        <div className=" flex flex-row justify-between  gap-4 w-fit   ">
            <p className=" text-[24px] border-paragraph-light font-light border-l-[1px] pe-2">
                {title}
            </p>
            <div className="     flex flex-row justify-evenly items-center gap-2 ">
                <span dangerouslySetInnerHTML={{ __html: icon }}></span>
                <p className=" font-light  text-paragraph-light  text-[14px] whitespace-nowrap ">
                    {subtitle}
                </p>
            </div>
        </div>
    );
};

export default Heading;
