import React, { useState } from "react";
import { useAddFile } from "../hook/image";
import { baseURL } from "../hook/request";
import { CircularProgress } from "@mui/material";

const InputImage = ({ setImageUrl, ImageUrl, type }) => {
    const [loading, setLoading] = useState(false);
    const { mutate } = useAddFile({
        onSuccess: (result) => {
            setImageUrl(result);
            setLoading(false);
        },
        onError: (error) => {
            setLoading(false);
            console.log(error.message);
        },
        type,
    });

    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoading(true);
            mutate(file);
        }
    };

    return (
        <div className="sm:w-[329px]  sm:max-h-[247px]   w-[150px] max-h-[150px]  overflow-hidden flex flex-col gap-2 ">
            <p className="  font-light text-[12px]   ">الصوره</p>
            <form
                onClick={() => document.querySelector(".add-service").click()}
                action=""
                className=" overflow-hidden object-cover  flex flex-col justify-center items-center border-2 border-dashed border-main max-w-[329px]  h-[247px] cursor-pointer "
            >
                {!ImageUrl && !loading && (
                    <div className=" w-[23px] h-[21px] bg-main text-white font-bold   items-center  flex justify-center  rounded-[3px] text-[20px]  ">
                        <span>+</span>
                    </div>
                )}
                {loading && <CircularProgress />}
                <input
                    type="file"
                    accept="image/*"
                    hidden
                    className="add-service"
                    onChange={changeHandler}
                />
                {ImageUrl && !loading && (
                    <img
                        src={`${baseURL}${ImageUrl}`}
                        alt=""
                        width={329}
                        height={250}
                        className=" object-cover"
                    />
                )}
            </form>
        </div>
    );
};

export default InputImage;
